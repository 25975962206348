import { render, staticRenderFns } from "./userAgreement.vue?vue&type=template&id=219b32a4&scoped=true"
import script from "./userAgreement.vue?vue&type=script&lang=js"
export * from "./userAgreement.vue?vue&type=script&lang=js"
import style0 from "./userAgreement.vue?vue&type=style&index=0&id=219b32a4&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "219b32a4",
  null
  
)

export default component.exports